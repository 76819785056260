export const begrippen = [
  "Hond",
  "Kat",
  "Auto",
  "Fiets",
  "Boek",
  "Telefoon",
  "Computer",
  "Regen",
  "Zon",
  "Maan",
  "Ster",
  "Koffie",
  "Thee",
  "Melk",
  "Pizza",
  "Brood",
  "Kip",
  "Vis",
  "Zwembad",
  "Strand",
  "Berg",
  "Boom",
  "Bloem",
  "Huis",
  "School",
  "Vliegtuig",
  "Trein",
  "Schip",
  "Bal",
  "Spel",
  "Muziek",
  "Film",
  "Lachen",
  "Zingen",
  "Dans",
  "Slapen",
  "Geld",
  "Tijd",
  "Liefde",
  "Vriend",
  "Familie",
  "Eten",
  "Drinken",
  "Sport",
  "Spel",
  "Reis",
  "Vakantie",
  "Feest",
  "Verjaardag",
  "Kerst",
  "Halloween",
  "Pasen",
  "Zomer",
  "Winter",
  "Herfst",
  "Lente",
  "Kleuren",
  "Rood",
  "Blauw",
  "Groen",
  "Geel",
  "Oranje",
  "Paars",
  "Zwart",
  "Wit",
  "Grijs",
  "Bruin",
  "Roze",
  "Laptop",
  "Spelcomputer",
  "Gitaar",
  "Piano",
  "Camera",
  "Zonnebril",
  "Horloge",
  "Boodschappen",
  "Sleutel",
  "Deur",
  "Raam",
  "Stoel",
  "Tafel",
  "Bed",
  "Kussen",
  "Kleding",
  "Schoenen",
  "Hoed",
  "Tas",
  "Portemonnee",
  "Televisie",
  "Radio",
  "Krant",
  "Tijdschrift",
  "Wolken",
  "Regenboog",
  "Wind",
  "Sneeuw",
  "IJs",
  "Vuur",
  "Aarde",
  "Zee",
  "Rivier",
  "Meer",
  "Zand",
  "Steen",
  "Gras",
  "Bloed",
  "Botten",
  "Hart",
  "Brein",
  "Oog",
  "Oor",
  "Neus",
  "Mond",
  "Hand",
  "Voet",
];
