export const sport = [
  "Voetbal",
  "Hockey",
  "Tennis",
  "Schaatsen",
  "Zwemmen",
  "Korfbal",
  "Atletiek",
  "Handbal",
  "Basketbal",
  "Volleybal",
  "Badminton",
  "Tafeltennis",
  "Wielrennen",
  "Mountainbiken",
  "Paardrijden",
  "Golf",
  "Zeilen",
  "Surfen",
  "Kitesurfen",
  "Windsurfen",
  "Roeien",
  "Kanoën",
  "Boksen",
  "Judo",
  "Karate",
  "Taekwondo",
  "Schermen",
  "Boogschieten",
  "Biljarten",
  "Darten",
  "Klimmen",
  "Duiken",
  "Squash",
  "Snooker",
  "Yoga",
  "Pilates",
  "Fitness",
  "Gymnastiek",
  "Trampolinespringen",
  "Capoeira",
  "Cricket",
  "Gewichtheffen",
  "Skateboarden",
  "Inline skaten",
  "Wakeboarden",
  "Motocross",
  "Freerunning",
  "Wandelen",
  "Hardlopen",
  "Fietsen",
  "Mountainbiken",
  "Turnen",
  "Touwtjespringen",
  "Boogschieten",
  "Skiën",
  "Snowboarden",
  "Schaatsen",
  "Biljart",
  "Dammen",
  "Vissen",
  "Klimsport",
  "Polsstokhoogspringen",
  "Bungeejumpen",
  "Paintball",
  "Hordelopen",
  "Beachvolleybal",
  "Zaalvoetbal",
  "Dansen",
  "Kajakken",
  "Waterpolo",
  "Waterskiën",
  "Kiteboarden",
  "BMX",
  "Triatlon",
  "Boogschieten",
  "Worstelen",
  "Rugby",
  "Snorkelen",
  "Pencak Silat",
  "Curling",
  "Hengelen",
  "Handboogschieten",
  "Lacrosse",
  "Surf lifesaving",
  "Petanque",
  "Polstokhoogspringen",
  "Kogelstoten",
  "Sprint",
  "Hoogspringen",
  "Verspringen",
  "Hink-stap-springen",
  "Speerwerpen",
  "Discuswerpen",
  "Kogelslingeren",
  "Marathon",
  "Wandklimmen",
  "Kunstrijden",
  "Skeeleren",
  "Zaalhockey",
  "Honkbal",
  "Softbal",
  "Bridge",
  "Korfbal",
  "Lionel Messi",
  "Cristiano Ronaldo",
  "Usain Bolt",
  "Michael Jordan",
  "Serena Williams",
  "Roger Federer",
  "LeBron James",
  "Muhammad Ali",
  "Diego Maradona",
  "Kobe Bryant",
  "Tom Brady",
  "Megan Rapinoe",
  "Rafael Nadal",
  "Novak Djokovic",
  "Simone Biles",
  "Michael Phelps",
  "Tiger Woods",
  "Marta",
  "Steph Curry",
  "Carli Lloyd",
  "Zlatan Ibrahimović",
  "Caster Semenya",
  "Manny Pacquiao",
  "Ronda Rousey",
  "Virat Kohli",
  "Katie Ledecky",
  "Naomi Osaka",
  "Lewis Hamilton",
  "Neeraj Chopra",
  "Simone Manuel",
  "Wayne Rooney",
  "Caeleb Dressel",
  "Luka Modrić",
  "Anthony Joshua",
  "Allyson Felix",
  "Neymar Jr.",
  "Kawhi Leonard",
  "Lindsey Vonn",
  "Andy Murray",
  "Sachin Tendulkar",
  "Michael Schumacher",
  "Mikaela Shiffrin",
  "Carmelo Anthony",
  "Luka Dončić",
  "Manny Pacquiao",
  "Katarina Johnson-Thompson",
  "Alex Morgan",
  "Kevin Durant",
  "Ronda Rousey",
  "Chris Froome",
  "Coco Gauff",
  "Son Heung-min",
  "Michael Phelps",
  "Thomas Müller",
  "Marcel Hirscher",
  "Yuna Kim",
  "Lindsey Horan",
  "Kenenisa Bekele",
  "Gemma Tattersall",
  "Sandy Koufax",
  "Ashton Eaton",
  "Sadio Mané",
  "Michael Phelps",
  "Thomas Müller",
  "Marcel Hirscher",
  "Yuna Kim",
  "Lindsey Horan",
  "Kenenisa Bekele",
  "Gemma Tattersall",
  "Sandy Koufax",
  "Ashton Eaton",
  "Sadio Mané",
  "Simone Manuel",
  "Adam Peaty",
  "Federica Pellegrini",
  "Mo Farah",
  "Venus Williams",
  "Adam Ondra",
  "Nina Derwael",
  "Paula Radcliffe",
  "Max Schmeling",
  "Anna van der Breggen",
  "Gwen Jorgensen",
  "Eliud Kipchoge",
  "Abby Wambach",
  "Jan Frodeno",
  "Emma Coburn",
  "Alexander Zverev",
  "Lily Parr",
  "Carolina Marín",
  "Max Whitlock",
  "Briana Williams",
  "Real Madrid",
  "FC Barcelona",
  "Manchester United",
  "Liverpool FC",
  "Bayern München",
  "Paris Saint-Germain",
  "Juventus",
  "AC Milan",
  "Chelsea FC",
  "Arsenal FC",
  "Manchester City",
  "Tottenham Hotspur",
  "Ajax Amsterdam",
  "PSV Eindhoven",
  "Feyenoord",
  "Borussia Dortmund",
  "Bayer Leverkusen",
  "RB Leipzig",
  "Olympique Marseille",
  "Olympique Lyon",
  "AS Roma",
  "Inter Milan",
  "ACF Fiorentina",
  "SSC Napoli",
  "Benfica",
  "Porto",
  "Sporting Lissabon",
  "Celtic",
  "Rangers",
  "Galatasaray",
  "Fenerbahçe",
  "Besiktas",
  "Anderlecht",
  "Club Brugge",
  "Standard Luik",
  "Red Bull Salzburg",
  "Rosenborg",
  "AIK Stockholm",
  "Malmö FF",
  "FC Kopenhagen",
  "Brøndby IF",
  "Zenit Sint-Petersburg",
  "Spartak Moskou",
  "Dinamo Kiev",
  "Shakhtar Donetsk",
  "Celtic Glasgow",
  "Glasgow Rangers",
  "Olympiakos",
  "Panathinaikos",
  "Rapid Wien",
];
