export const media = [
  "Friends",
  "Game of Thrones",
  "Breaking Bad",
  "Stranger Things",
  "The Office (US)",
  "The Big Bang Theory",
  "House",
  "Grey's Anatomy",
  "The Simpsons",
  "Lost",
  "How I Met Your Mother",
  "Seinfeld",
  "Family Guy",
  "South Park",
  "The Crown",
  "The Walking Dead",
  "The Wire",
  "Mad Men",
  "Westworld",
  "The Sopranos",
  "The X-Files",
  "Doctor Who",
  "Downton Abbey",
  "Black Mirror",
  "Narcos",
  "Fargo",
  "The Handmaid's Tale",
  "Better Call Saul",
  "The Haunting of Hill House",
  "True Detective",
  "Supernatural",
  "Riverdale",
  "The Vampire Diaries",
  "Chernobyl",
  "Friends",
  "Stranger Things",
  "Breaking Bad",
  "Grey's Anatomy",
  "The Office",
  "The Big Bang Theory",
  "House",
  "The Walking Dead",
  "Lost",
  "How I Met Your Mother",
  "Family Guy",
  "South Park",
  "The Crown",
  "Westworld",
  "Doctor Who",
  "Black Mirror",
  "Narcos",
  "The Handmaid's Tale",
  "The Haunting of Hill House",
  "True Detective",
  "Supernatural",
  "Riverdale",
  "The Vampire Diaries",
  "Friends",
  "Stranger Things",
  "Breaking Bad",
  "Grey's Anatomy",
  "The Office",
  "The Big Bang Theory",
  "House",
  "The Walking Dead",
  "Lost",
  "How I Met Your Mother",
  "Family Guy",
  "South Park",
  "The Crown",
  "Westworld",
  "Doctor Who",
  "Black Mirror",
  "Narcos",
  "The Handmaid's Tale",
  "The Haunting of Hill House",
  "True Detective",
  "Supernatural",
  "Riverdale",
  "The Vampire Diaries",
  "Friends",
  "Stranger Things",
  "Breaking Bad",
  "Grey's Anatomy",
  "The Office",
  "The Big Bang Theory",
  "House",
  "The Walking Dead",
  "Lost",
  "How I Met Your Mother",
  "Family Guy",
  "South Park",
  "The Crown",
  "Westworld",
  "Doctor Who",
  "Black Mirror",
  "Narcos",
  "The Handmaid's Tale",
  "The Haunting of Hill House",
  "True Detective",
  "Supernatural",
  "Riverdale",
  "The Vampire Diaries",
  "The Shawshank Redemption",
  "The Godfather",
  "The Dark Knight",
  "Pulp Fiction",
  "Fight Club",
  "Forrest Gump",
  "Inception",
  "The Matrix",
  "Goodfellas",
  "Schindler's List",
  "The Lion King",
  "Star Wars",
  "Titanic",
  "Jurassic Park",
  "Avatar",
  "The Terminator",
  "Back to the Future",
  "The Green Mile",
  "Casablanca",
  "Gone with the Wind",
  "The Wizard of Oz",
  "The Sound of Music",
  "Citizen Kane",
  "Lawrence of Arabia",
  "The Good, the Bad and the Ugly",
  "Once Upon a Time in the West",
  "The Great Gatsby",
  "Gone Girl",
  "The Wolf of Wall Street",
  "Shutter Island",
  "Inglourious Basterds",
  "Django Unchained",
  "Kill Bill",
  "The Avengers",
  "The Silence of the Lambs",
  "Gladiator",
  "The Departed",
  "Jaws",
  "E.T. the Extra-Terrestrial",
  "Indiana Jones",
  "The Shining",
  "A Clockwork Orange",
  "The Exorcist",
  "The Breakfast Club",
  "The Princess Bride",
  "Die Hard",
  "The Social Network",
  "The Sixth Sense",
  "The Truman Show",
  "The Big Lebowski",
  "Pirates of the Caribbean",
  "The Hunt for Red October",
  "The Bourne Identity",
  "The Shawshank Redemption",
  "The Godfather",
  "The Dark Knight",
  "Pulp Fiction",
  "Fight Club",
  "Forrest Gump",
  "Inception",
  "The Matrix",
  "Goodfellas",
  "Schindler's List",
  "The Lion King",
  "Star Wars",
  "Titanic",
  "Jurassic Park",
  "Avatar",
  "The Terminator",
  "Back to the Future",
  "The Green Mile",
  "Casablanca",
  "Gone with the Wind",
  "The Wizard of Oz",
  "The Sound of Music",
  "Citizen Kane",
  "Lawrence of Arabia",
  "The Good, the Bad and the Ugly",
  "Once Upon a Time in the West",
  "The Great Gatsby",
  "Gone Girl",
  "The Wolf of Wall Street",
  "Shutter Island",
  "Inglourious Basterds",
  "Django Unchained",
  "Kill Bill",
  "The Avengers",
  "The Silence of the Lambs",
  "Gladiator",
  "The Departed",
  "Jaws",
  "Indiana Jones",
  "The Shining",
  "A Clockwork Orange",
  "The Exorcist",
  "The Breakfast Club",
  "The Princess Bride",
  "Die Hard",
  "The Social Network",
  "The Sixth Sense",
  "The Truman Show",
  "The Big Lebowski",
  "Pirates of the Caribbean",
  "The Hunt for Red October",
  "The Bourne Identity",
];
