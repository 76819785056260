export const dieren = [
  "Leeuw",
  "Tijger",
  "Olifant",
  "Giraffe",
  "Krokodil",
  "Nijlpaard",
  "Pinguïn",
  "Kangoeroe",
  "Kameel",
  "Papegaai",
  "Haai",
  "Dolfijn",
  "Koala",
  "Aap",
  "Schildpad",
  "Pauw",
  "Zebra",
  "Flamingo",
  "Kreeft",
  "Octopus",
  "Vlinder",
  "Mier",
  "Bij",
  "Spin",
  "Slak",
  "Eekhoorn",
  "Kever",
  "Beer",
  "Draak",
  "Worm",
  "Eend",
  "Kip",
  "Varken",
  "Koe",
  "Schaap",
  "Geit",
  "Paard",
  "Ezel",
  "Lama",
  "Konijn",
  "Hamster",
  "Goudvis",
  "Papegaaivis",
  "Koi",
  "Krokodilvis",
  "Zeester",
  "Zeehond",
  "Walvis",
  "Krab",
  "Kwal",
  "Koraal",
  "Zeepaardje",
  "Zalm",
  "Forel",
  "Haan",
  "Meeuw",
  "Pelikaan",
  "Mus",
  "Adelaar",
  "Uil",
  "Zwaan",
  "Papegaaiduiker",
  "Duif",
  "Panda",
  "Koalabeer",
  "Chimpansee",
  "Orang-oetan",
  "Krokodil",
  "Flamingo",
  "Kangoeroe",
  "Cheetah",
  "Luipaard",
  "Gorilla",
  "Nijlpaard",
  "Nijlpaard",
  "Giraffe",
  "Kameel",
  "Krokodil",
  "Papegaai",
  "Tijger",
  "Leeuw",
  "Aap",
  "Olifant",
  "Zebra",
  "Slang",
  "Vleermuis",
  "Kameleon",
  "Schapen",
  "Hagedis",
  "Egel",
  "Vleermuis",
  "Kameleon",
  "Schapen",
  "Hagedis",
  "Egel",
  "Bever",
  "Neushoorn",
  "IJsbeer",
  "Mammoet",
  "Vos",
  "Mol",
  "Koekoek",
  "Kalkoen",
  "Hert",
  "Mier",
  "Vogelbekdier",
  "Zeeleeuw",
  "Pauw",
  "Zalm",
  "Cavia",
  "Gazelle",
  "Walvis",
  "Schildpad",
  "Narwal",
  "Pijlstaartrog",
  "Kwal",
  "Pinguïn",
  "Bizon",
  "Krokodil",
  "Kangoeroe",
  "Salamander",
  "Vlinder",
  "Libelle",
  "Wesp",
  "Sprinkhaan",
  "Reiger",
  "Rat",
  "Giraf",
  "Kreeft",
  "Fazant",
  "Struisvogel",
  "Gans",
  "Dodo",
  "Pelikaan",
  "Honingbij",
  "Roek",
  "Fluit",
  "Schreeuw",
  "Zing",
  "Lach",
  "Huil",
  "Fluister",
  "Neuriën",
  "Kreunen",
  "Hoesten",
  "Grommen",
  "Snurken",
  "Fluiten",
  "Blaffen",
  "Kuchen",
  "Knorren",
  "Hinniken",
  "Miauwen",
  "Tokken",
  "Kwaken",
  "Brommen",
  "Piepen",
  "Sissen",
  "Hijgen",
  "Briesen",
  "Loeien",
  "Blaten",
  "Grommen",
  "Kakelen",
  "Piepen",
  "Mauwen",
  "Zoemen",
  "Brommen",
  "Trompetteren",
  "Krijsen",
  "Blaft",
  "Klok",
  "Pen",
  "Potlood",
  "Borstel",
  "Televisie",
  "Radio",
  "Computer",
  "Lamp",
  "Stoel",
  "Tafel",
  "Bed",
  "Kussen",
  "Boekenkast",
  "Klok",
  "Telefoon",
  "Gitaar",
  "Piano",
  "Schilderij",
  "Spiegel",
  "Schaar",
  "Kleerhanger",
  "Vork",
  "Lepel",
  "Mes",
  "Kop",
  "Bord",
  "Pannen",
  "Oven",
  "Koelkast",
  "Vriezer",
  "Wasbak",
  "Bad",
  "Douche",
  "Toilet",
  "Zeep",
  "Shampoo",
  "Tandenborstel",
  "Tandpasta",
  "Handdoek",
  "Wasmand",
  "Waslijn",
  "Kleding",
  "Schoenen",
  "Hoed",
  "Riem",
  "Portemonnee",
  "Zonnebril",
  "Paraplu",
  "Bril",
  "Horloge",
  "Sieraden",
  "Tas",
  "Brievenbus",
  "Bel",
  "Sleutel",
  "Deur",
  "Raam",
  "Trap",
  "Kelder",
  "Zolder",
  "Gang",
  "Keuken",
  "Woonkamer",
  "Slaapkamer",
  "Badkamer",
  "Tuin",
  "Balkon",
  "Schuur",
  "Boom",
  "Bloem",
  "Gras",
  "Plant",
  "Bloempot",
  "Tak",
  "Blad",
  "Stam",
  "Wortel",
  "Knoop",
  "Water",
  "Zand",
  "Steen",
  "Aarde",
  "Lucht",
  "Vuur",
  "Wind",
  "Regen",
  "Sneeuw",
  "Hagel",
  "IJs",
  "Wolk",
  "Bliksem",
  "Mist",
  "Zon",
  "Maan",
  "Ster",
  "Regenboog",
  "Storm",
  "Dauw",
  "Vogel",
  "Vlinder",
  "Bij",
  "Wesp",
  "Mug",
  "Spin",
  "Kever",
  "Vlieg",
  "Mier",
  "Schildpad",
  "Kikker",
  "Vis",
  "Walvis",
  "Haai",
  "Dolfijn",
  "Krab",
  "Octopus",
  "Kreeft",
  "Zeehond",
  "IJsbeer",
  "Pinguïn",
  "Aap",
  "Olifant",
  "Leeuw",
  "Tijger",
  "Luipaard",
  "Giraffe",
  "Krokodil",
  "Nijlpaard",
  "Zebra",
  "Flamingo",
  "Kangoeroe",
  "Kameel",
  "Papegaai",
  "Koala",
  "Neushoorn",
  "Gorilla",
  "Slang",
  "Vleermuis",
  "Kameleon",
  "Koekoek",
  "Specht",
  "Ekster",
  "Mus",
  "Merel",
  "Adelaar",
  "Uil",
  "Reiger",
  "Pauw",
  "Zwaan",
  "Duif",
  "Panda",
  "Chimpansee",
  "Orang-oetan",
  "Bever",
  "Vos",
  "Mol",
  "Koekoek",
  "Kalkoen",
  "Hert",
  "Egel",
  "Vogelbekdier",
  "Zeeleeuw",
  "Bizon",
  "Fazant",
  "Struisvogel",
  "Gans",
  "Dodo",
  "Pelikaan",
  "Honingbij",
  "Roek",
  "Fluit",
  "Schreeuw",
  "Zing",
  "Lach",
  "Huil",
  "Fluister",
  "Neuriën",
  "Kreunen",
  "Hoesten",
  "Grommen",
  "Snurken",
  "Fluiten",
  "Blaffen",
  "Kuchen",
  "Knorren",
  "Hinniken",
  "Miauwen",
  "Tokken",
  "Kwaken",
  "Brommen",
  "Piepen",
  "Sissen",
  "Hijgen",
  "Briesen",
  "Loeien",
  "Blaten",
  "Grommen",
  "Kakelen",
  "Piepen",
  "Mauwen",
  "Zoemen",
  "Brommen",
  "Trompetteren",
  "Krijsen",
  "Klok",
  "Pen",
  "Potlood",
  "Borstel",
  "Televisie",
  "Radio",
  "Computer",
  "Lamp",
  "Stoel",
  "Tafel",
  "Bed",
  "Kussen",
  "Boekenkast",
  "Klok",
  "Telefoon",
  "Gitaar",
  "Piano",
  "Schilderij",
  "Spiegel",
  "Schaar",
  "Kleerhanger",
  "Vork",
  "Lepel",
  "Mes",
  "Kop",
  "Bord",
  "Pannen",
  "Oven",
  "Koelkast",
  "Vriezer",
  "Wasbak",
  "Bad",
  "Douche",
  "Toilet",
  "Zeep",
  "Shampoo",
  "Tandenborstel",
  "Tandpasta",
  "Handdoek",
  "Wasmand",
  "Waslijn",
  "Kleding",
  "Schoenen",
  "Hoed",
  "Riem",
  "Portemonnee",
  "Zonnebril",
  "Paraplu",
  "Bril",
  "Horloge",
  "Sieraden",
  "Tas",
  "Brievenbus",
  "Bel",
  "Sleutel",
  "Deur",
  "Raam",
  "Trap",
  "Kelder",
  "Zolder",
  "Gang",
  "Keuken",
  "Woonkamer",
  "Slaapkamer",
  "Badkamer",
  "Tuin",
  "Balkon",
  "Schuur",
  "Boom",
  "Bloem",
  "Gras",
  "Plant",
  "Bloempot",
  "Tak",
  "Blad",
  "Stam",
  "Wortel",
  "Knoop",
  "Water",
  "Zand",
  "Steen",
  "Aarde",
  "Lucht",
  "Vuur",
  "Wind",
  "Regen",
  "Sneeuw",
  "Hagel",
  "IJs",
  "Wolk",
  "Bliksem",
  "Mist",
  "Zon",
  "Maan",
  "Ster",
  "Regenboog",
  "Storm",
  "Dauw",
  "Vogel",
  "Vlinder",
  "Bij",
  "Wesp",
  "Mug",
  "Spin",
  "Kever",
  "Vlieg",
  "Mier",
  "Schildpad",
  "Kikker",
  "Vis",
  "Walvis",
  "Haai",
  "Dolfijn",
  "Krab",
  "Octopus",
  "Kreeft",
  "Zeehond",
  "IJsbeer",
  "Pinguïn",
  "Aap",
  "Olifant",
  "Leeuw",
  "Tijger",
  "Luipaard",
  "Giraffe",
  "Krokodil",
  "Nijlpaard",
  "Zebra",
  "Flamingo",
  "Kangoeroe",
  "Kameel",
  "Papegaai",
  "Koala",
  "Neushoorn",
  "Gorilla",
  "Slang",
  "Vleermuis",
  "Kameleon",
  "Koekoek",
  "Specht",
  "Ekster",
  "Mus",
  "Merel",
  "Adelaar",
  "Uil",
  "Reiger",
  "Pauw",
  "Zwaan",
  "Duif",
  "Panda",
  "Chimpansee",
  "Orang-oetan",
  "Bever",
  "Vos",
  "Mol",
  "Koekoek",
  "Kalkoen",
  "Hert",
  "Egel",
];
