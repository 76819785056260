export const bners = [
  "Marco Borsato",
  "Anouk",
  "Armin van Buuren",
  "André Hazes",
  "Gordon",
  "Ilse DeLange",
  "Guus Meeuwis",
  "Doutzen Kroes",
  "Wesley Sneijder",
  "Robin van Persie",
  "Rico Verhoeven",
  "Max Verstappen",
  "Famke Louise",
  "Ranomi Kromowidjojo",
  "Carice van Houten",
  "Michiel Huisman",
  "Humberto Tan",
  "Najib Amhali",
  "Typhoon",
  "Ronald Koeman",
  "Annie M.G. Schmidt",
  "Heleen van Royen",
  "Kees van Kooten",
  "Wim Hof",
  "Martijn Krabbé",
  "John de Mol",
  "Linda de Mol",
  "Chantal Janzen",
  "André Rieu",
  "Waylon",
  "Nikkie de Jager",
  "Rutger Hauer",
  "Trijntje Oosterhuis",
  "Bridget Maasland",
  "Sander Schimmelpenninck",
  "Andries Knevel",
  "Freek Vonk",
  "Geert Wilders",
  "Wim Sonneveld",
  "Hans Klok",
  "Eva Jinek",
  "Dafne Schippers",
  "Femke Halsema",
  "Georgina Verbaan",
  "Louis van Gaal",
  "Peter R. de Vries",
  "Matthijs van Nieuwkerk",
  "Jan Smit",
  "Johan Derksen",
  "Frans Bauer",
  "Ruud Gullit",
  "Dick Advocaat",
  "Sander Lantinga",
  "Giel Beelen",
  "Jeroen Pauw",
  "Dries Roelvink",
  "Janine Jansen",
  "Michaëlla Krajicek",
  "Anky van Grunsven",
  "Yolanthe Sneijder-Cabau",
  "Doutzen Kroes",
  "André van Duin",
  "Freek de Jonge",
  "Wendy van Dijk",
  "Waylon",
  "Lieke Martens",
  "Sanne Wevers",
  "Guus Hiddink",
  "Badr Hari",
  "Angela Groothuizen",
  "André Kuipers",
  "Ali B",
  "Sylvie Meis",
  "Wendy van Dijk",
  "Rico Verhoeven",
  "Najib Amhali",
  "Jandino Asporaat",
  "Carlo Boszhard",
  "Gerard Joling",
  "Jeroen van Koningsbrugge",
  "Jan Kooijman",
  "Nicolette van Dam",
  "Tatjana Simić",
];
